import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { Button } from '@aph/components/common/button';
import { ChatEventTopic } from '@aph/components/common/chat/chat-event-topic';
import {
  useChatActions,
  useChatStore,
} from '@aph/components/common/chat/chat-store/use-chat-store';
import { useChatMediator } from '@aph/components/common/chat/use-chat-mediator';
import { RichTextComponent } from '~/contentful/components/rich-text/richtext.component';
import type { ICustomerServiceContactUsCard } from '~/contentful/types';

export const ContactUsCard = ({ fields }: ICustomerServiceContactUsCard) => {
  const { title, description, body, ctaText, cta } = fields;
  const [isClicked, setIsClicked] = useState(false);

  const onHandleClick = () => {
    if (!isClicked) setIsClicked(true);
  };

  const { notify } = useChatMediator();
  const [isMaximized] = useChatStore((state) => [state.chatState.isMaximized]);
  const { setIsMaximized } = useChatActions();

  const handleOpenChat = () => {
    if (isMaximized) {
      return;
    }
    setIsMaximized(true);
    notify(ChatEventTopic.Play);
  };

  return (
    <Box
      bgcolor={(theme) => theme.palette['color/background/elevated']}
      borderRadius={3}
      py={2}
      px={1.5}
    >
      <Stack gap={1.5}>
        <Typography variant="h3">{title}</Typography>
        <Typography>{description}</Typography>
      </Stack>
      <Box my={1.5}>
        <Divider />
      </Box>
      <RichTextComponent field={body} />
      <Box width={{ sm: 160 }} mt={2}>
        {
          {
            chat: (
              <Button data-pw="contact-us-card-chat-button" onClick={handleOpenChat} fullWidth>
                {ctaText}
              </Button>
            ),
            phone: (
              <>
                <Box width={{ sm: 160 }} mt={2} display={{ xs: 'none', sm: 'block' }}>
                  <Button data-pw="contact-us-card-phone-button" onClick={onHandleClick} fullWidth>
                    <Box display={{ xs: 'none', sm: 'block' }}>
                      {isClicked ? '0771-405 405' : ctaText}
                    </Box>
                  </Button>
                </Box>
                <Box width={{ sm: 160 }} mt={2} display={{ xs: 'block', sm: 'none' }}>
                  <Button data-pw="contact-us-card-phone-button" href="tel:+46771405405" fullWidth>
                    <FormattedMessage id="CUSTOMER_SERVICE.CONTACT_US.CARD_PHONE.CALL_CTA.MOBILE" />
                  </Button>
                </Box>
              </>
            ),
            mail: (
              <Button
                data-pw="contact-us-card-mail-button"
                href="mailto:kundservice@apotekhjartat.se"
                fullWidth
              >
                {ctaText}
              </Button>
            ),
          }[cta]
        }
      </Box>
    </Box>
  );
};
