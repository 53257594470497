import { useRef } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';
import { sendEcommerce } from '@aph/components/gtm/useGtm';
import type { IImageLink, IPromotionPanel } from '~/contentful/types';
import { Banner } from '../banner/banner';
import { ImageLink } from '../image-link/image-link';

enum ImageCount {
  FEW = 2,
  MANY = 4,
}

export type PromotionPanelProps = IPromotionPanel & {
  asBannerOnMobile?: boolean;
};

const getTrackingData = (
  image: IImageLink,
  panel: Pick<IPromotionPanel, 'fields' | 'sys'>,
  index = 0,
) => ({
  creative_name: image.fields.title,
  creative_slot: `promotion_panel_${index}`,
  promotion_id: panel.sys.id,
  promotion_name: panel.fields.title,
});

export const PromotionPanel = ({ fields, sys, asBannerOnMobile }: PromotionPanelProps) => {
  const { imageLink } = fields;
  const shownImageIds = useRef<string[]>([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const showBanner = asBannerOnMobile && isXs;

  if (imageLink.length <= 0) {
    return null;
  }

  const gridTemplateColumns =
    imageLink.length === ImageCount.MANY
      ? {
          xs: 'repeat(2, minmax(0, 1fr))',
          sm: 'repeat(4, minmax(0, 1fr))',
        }
      : {
          xs: 'repeat(1, minmax(0, 1fr))',
          sm: 'repeat(2, minmax(0, 1fr))',
        };

  const sendViewPromotion = (image: IImageLink, index: number) => {
    if (!shownImageIds.current.includes(image.sys.id)) {
      shownImageIds.current = [...shownImageIds.current, image.sys.id];
      sendEcommerce({
        event: 'view_promotion',
        ecommerce: getTrackingData(image, { fields, sys }, index),
      });
    }
  };

  const sendSelectPromotion = (image: IImageLink, index: number) => {
    sendEcommerce({
      event: 'select_promotion',
      ecommerce: getTrackingData(image, { fields, sys }, index),
    });
  };

  return (
    <Box data-testid="PromotionPanel" width="100%">
      <Box display={asBannerOnMobile ? { xs: 'block', sm: 'none' } : { xs: 'none' }}>
        <Banner
          imageLinks={imageLink}
          slideIntervalInSeconds={5}
          onClick={sendSelectPromotion}
          onChange={(image, index) => {
            if (showBanner) {
              sendViewPromotion(image, index);
            }
          }}
        />
      </Box>
      <Box
        display={asBannerOnMobile ? { xs: 'none', sm: 'grid' } : { xs: 'grid' }}
        gridTemplateColumns={gridTemplateColumns}
        rowGap={{ xs: 1, sm: 2 }}
        columnGap={{ xs: 1, sm: 2 }}
      >
        {imageLink.map((image, index) => {
          return (
            <ImageLink
              key={image.sys.id}
              href={image.fields.link}
              src={image.fields.image.fields.file.url}
              alt={image.fields.image.fields.title}
              height={image.fields.image.fields.file.details?.image?.height}
              width={image.fields.image.fields.file.details?.image?.width}
              sizes="50vw"
              onInView={() => {
                if (!showBanner) {
                  sendViewPromotion(image, index);
                }
              }}
              onClick={() => sendSelectPromotion(image, index)}
            />
          );
        })}
      </Box>
    </Box>
  );
};
