import NextLink from 'next/link';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Typography } from '@aph/ui/components/typography/typography';
import {
  ContentfulImage,
  type ContentfulImageProps,
} from '../next-contentful-image/next-contentful-image';
import {
  type ContentCardColor,
  getPrimaryColor,
  getSecondaryColor,
} from './get-content-card-colors';

export type ContentCardItem = {
  title: string;
  description?: string;
  href: string;
  image: ContentfulImageProps['src'] | null | undefined;
};

type ContentCardProps = {
  item: ContentCardItem;
  color: ContentCardColor;
  onClick?: (item: ContentCardProps['item']) => void;
};

export const ContentCard = ({ item, color, onClick }: ContentCardProps) => {
  if (isContentCardItemWithImage(item)) {
    return (
      <div
        className="flex h-[405px] flex-col overflow-hidden rounded-2xl md:h-[481px]"
        style={{
          '--primaryColor': getPrimaryColor(color),
          '--secondaryColor': getSecondaryColor(color),
        }}
      >
        <NextLink href={item.href} onClick={() => onClick?.(item)} className="h-full no-underline">
          <div className="relative h-1/2 w-full shrink-0">
            <ContentfulImage
              src={item.image}
              alt={item.description ?? item.title}
              fill
              sizes="25vw"
              style={{
                objectFit: 'cover',
              }}
            />
          </div>

          <div className="flex h-1/2 flex-col">
            <div className="relative">
              <svg
                viewBox="0 0 266 22"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 left-[-3px] right-0 z-[2] h-[22px] w-[103%] translate-y-[2px] fill-[var(--primaryColor)]"
              >
                <path d="M287.727 23.415c-.569.63-1.143 1.273-1.169 1.936-.06 1.472 3.268 48.733 6.01 49.92H-39V29.598c14.82-1.885 28.442-5.354 40.047-8.938C17.647 15.532 30.46 8.895 49.32 4.731c30.398-6.712 69.317-6.234 98.489 1.127 14.119 3.563 25.915 8.221 41.068 11.28 26.348 5.318 60.737 5.339 87.891.791 3.146-.526 13.134-2.524 16.061-1.185.583.266.319.653.028.976l-5.132 5.695h.002Z" />
              </svg>
              <svg
                viewBox="0 0 200 33"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 right-0 z-[1] h-[22px] w-3/5 translate-y-px fill-[var(--secondaryColor)]"
              >
                <path d="M63.956 27.221c46.482-27.897 96.218-31.2 148.796-22.909 45.663 7.197 91.905 13.21 138.03 14.53 62.062 1.751 82.21 19.047 99.171 78.335 20.251 70.788 46.249 139.987 71.049 209.411 13.733 38.468 23.74 75.92-5.744 111.442-24.146 29.054-55.235 46.125-93.012 49.793-25.41 2.451-47.537-5.116-65.269-24.137-18.052-19.367-35.37-39.544-54.504-57.783-46.514-44.38-103.391-69.263-164.675-86.151C68.539 280.683 24.304 235.6 6.331 166.06-9.539 104.591 9.455 60.331 63.956 27.221Z" />
              </svg>
            </div>

            <div className="relative h-full bg-[var(--primaryColor)] px-1.5 py-2 md:p-3">
              <div className="flex flex-col gap-y-0.5 md:gap-y-1.5">
                <Typography typography="headingMedium" color="text-brand" className="truncate">
                  {item.title}
                </Typography>
                {item.description && (
                  <Typography typography="body" color="text-brand" className="line-clamp-2">
                    {item.description}
                  </Typography>
                )}
              </div>
              <div className="absolute bottom-0 left-0 p-[inherit]">
                <div className="shrink-0 md:hidden">
                  <ButtonCircle variant="white" icon="ArrowRight" size="medium" />
                </div>
                <div className="hidden shrink-0 md:block">
                  <ButtonCircle variant="white" icon="ArrowRight" size="large" />
                </div>
              </div>
            </div>
          </div>
        </NextLink>
      </div>
    );
  }

  return null;
};
function isContentCardItemWithImage(item: ContentCardItem): item is ContentCardItem & {
  image: NonNullable<ContentCardItem['image']>;
} {
  return item.image !== null;
}
