import { Box } from '@mui/material';
import type { Sys } from 'contentful';
import { DevelopmentOnly } from '@aph/components/common/development-only/development-only.component';
import { ContactUsCard } from '@aph/components/customer-service/contact-us-card/contact-us-card.component';
import type { CONTENT_TYPE } from '../../types';
import { CategoryArticlesPanel } from '../category-articles-panel/category-articles-panel';
import { Image } from '../image/image';
import { NavigationListing } from '../navigation-listing/navigation-listing.component';
import { ProductPanel } from '../product-panel/product-panel';
import { PromotionPanel } from '../promotion-panel/promotion-panel';
import { PsidArticlePanel } from '../psid-article-panel/psid-article-panel';
import { RichTextSimpleComponent } from '../rich-text/richtext-simple.component';
import { VideoComponent } from '../video/video.component';

type ContentTypeMappings = Partial<Record<CONTENT_TYPE, React.ElementType>>;

const ContentTypeMap: ContentTypeMappings = {
  image: Image,
  componentRichText: RichTextSimpleComponent,
  psidArticlePanel: PsidArticlePanel,
  categoryArticlesPanel: CategoryArticlesPanel,
  promotionPanel: PromotionPanel,
  productPanel: ProductPanel,
  customerServiceContactUsCard: ContactUsCard,
  navigation: NavigationListing,
  componentVideo: VideoComponent,
};

type Content = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  sys: Sys;
};

type PageContentProps = {
  content: Content | Content[] | undefined;
  mappings?: ContentTypeMappings;
};

const getComponent = (contentTypeId: string, mappings?: ContentTypeMappings) => {
  return mappings?.[contentTypeId as CONTENT_TYPE] ?? ContentTypeMap[contentTypeId as CONTENT_TYPE];
};

export const ContentRenderer = ({ content, mappings }: PageContentProps) => {
  if (!content) {
    return null;
  }

  if (Array.isArray(content)) {
    return content.map((block) => (
      <ContentRenderer key={`block-${block.sys.id}`} content={block} mappings={mappings} />
    ));
  }

  const contentTypeId = content.sys.contentType.sys.id;
  const Component = getComponent(contentTypeId, mappings);

  if (!Component) {
    return (
      <DevelopmentOnly>
        <Box
          bgcolor={(theme) => theme.palette['color/background/error']}
          color={(theme) => theme.palette['color/text/inverse']}
          p={2}
          borderRadius={2}
        >
          The Content renderer does not support component with contentType:
          <b>{contentTypeId}</b>, either add it in the ContentTypeMap or modify the data you are
          sending
        </Box>
      </DevelopmentOnly>
    );
  }

  const componentProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(content as any),
  };

  return componentProps && <Component {...componentProps} />;
};
