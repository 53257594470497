import type { UIEventHandler } from 'react';
import { useRef, useState } from 'react';
import { Box, Skeleton, Stack } from '@mui/material';
import chunk from 'lodash/chunk';
import { DottedPagination } from '@aph/components/common/dotted-pagination';
import { Typography } from '@aph/ui/components/typography/typography';
import { ArticleGrid, type ArticleGridProps } from '../article-grid/article-grid';
import { useCardAmount } from './use-card-amount';

type ArticleCarouselLoading = {
  isLoading: boolean;
};

type ArticleCarouselLoaded = {
  title?: string;
  articles: ArticleGridProps['articles'];
};

type Props = ArticleCarouselLoading | ArticleCarouselLoaded;

export type ArticleCarouselProps = Props & {
  listName?: string;
};

const isLoading = (props: Props): props is ArticleCarouselLoading => {
  return 'isLoading' in props && props.isLoading;
};

export const ArticleCarousel = (props: ArticleCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const amountToShow = useCardAmount();

  if (isLoading(props)) {
    return (
      <Stack data-testid="ArticleCarouselLoader" gap={3}>
        <Skeleton component="h2" width="150px" />
        <ArticleGrid totalCount={amountToShow} isLoadingNext />
      </Stack>
    );
  }

  const { listName, articles, title } = props;

  const onScroll: UIEventHandler<HTMLDivElement> = ({ currentTarget }) => {
    const index = Math.round(currentTarget.scrollLeft / currentTarget.offsetWidth);
    if (index !== currentIndex) {
      setCurrentIndex(index);
    }
  };

  const onPaginate = (index: number) => {
    containerRef.current?.scrollTo({
      left: index * (containerRef.current?.clientWidth || 0),
      behavior: 'smooth',
    });
  };

  const articlePages = chunk(articles, amountToShow);

  return (
    <Stack gap={3} data-testid="ArticleCarousel">
      {title ? (
        <Typography typography="headingMedium" color="text-brand" asChild>
          <h2>{title}</h2>
        </Typography>
      ) : null}
      <Box
        width="100%"
        whiteSpace="nowrap"
        sx={{
          scrollSnapType: 'x mandatory',
          overflowX: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={containerRef}
        onScroll={onScroll}
      >
        {articlePages.map((articleList) => (
          <Box
            key={articleList[0].articleCode}
            display="inline-flex"
            width="100%"
            sx={{
              scrollSnapAlign: 'start',
            }}
          >
            <ArticleGrid listName={listName || title} articles={articleList} />
          </Box>
        ))}
      </Box>
      <DottedPagination
        decreasePage={() => onPaginate(currentIndex - 1)}
        increasePage={() => onPaginate(currentIndex + 1)}
        amountOfPages={articlePages.length}
        currentPageIndex={currentIndex}
      />
    </Stack>
  );
};
