import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';

interface PaginationButtonProps {
  onPaginate: () => void;
  direction: 'prev' | 'next';
}

export const PaginationButton = ({ onPaginate, direction }: PaginationButtonProps) => (
  <ButtonCircle
    data-testid="PaginationButton"
    onClick={onPaginate}
    size="medium"
    variant="white"
    icon={direction === 'prev' ? 'ArrowheadLeft' : 'ArrowheadRight'}
  />
);
