import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PaginationButton } from './pagination-button';

export interface DottedPaginationProps {
  decreasePage: () => void;
  increasePage: () => void;
  amountOfPages: number;
  currentPageIndex: number;
  hasDarkBackground?: boolean;
}

enum DotStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const DottedPagination = ({
  decreasePage,
  increasePage,
  amountOfPages = 0,
  currentPageIndex,
  hasDarkBackground = false,
}: DottedPaginationProps) => {
  const theme = useTheme();
  if (amountOfPages <= 1) {
    return null;
  }

  const dotColor = hasDarkBackground
    ? {
        [DotStatus.ACTIVE]: theme.palette['color/icon/subtle'],
        [DotStatus.INACTIVE]: theme.palette['color/background/default'],
      }
    : {
        [DotStatus.ACTIVE]: theme.palette['color/background/action-dark'],
        [DotStatus.INACTIVE]: theme.palette['color/background/visual-dark'],
      };

  return (
    <Stack
      flexDirection="row"
      gap={3}
      alignItems="center"
      justifyContent="center"
      data-testid="DottedPagination"
      data-pw="dotted-pagination"
    >
      <PaginationButton
        data-pw="dotted-pagination-prev-button"
        onPaginate={() => decreasePage()}
        direction="prev"
      />
      <Stack flexDirection="row" gap={1}>
        {[...Array(amountOfPages).keys()].map((num) => {
          const isActive = num === currentPageIndex;
          return (
            <Box
              data-testid="PaginationDot"
              data-active={isActive}
              bgcolor={dotColor[isActive ? DotStatus.ACTIVE : DotStatus.INACTIVE]}
              width={8}
              height={8}
              borderRadius={4}
              key={num}
            />
          );
        })}
      </Stack>
      <PaginationButton
        data-pw="dotted-pagination-next-button"
        onPaginate={() => increasePage()}
        direction="next"
      />
    </Stack>
  );
};
