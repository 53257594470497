import NextLink from 'next/link';
import { Box, Link, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { sendEvent } from '@aph/components/gtm/useGtm';
import { Typography } from '@aph/ui/components/typography/typography';
import { urlify } from '@aph/utilities/slug';
import type { IBrandPage } from '~/contentful/types';
import { ContentfulImage } from '../next-contentful-image/next-contentful-image';

interface BrandCardProps {
  brand: IBrandPage;
}

export const BrandCard = ({ brand }: BrandCardProps) => {
  const theme = useTheme();

  const { logo, slug, pageTitle } = brand.fields;

  const trackBrandLogoClick = () => {
    sendEvent({
      event: 'brand_logolink_click',
      click_text: pageTitle,
    });
  };

  return (
    <Link
      data-pw="brand-card"
      component={NextLink}
      onClick={() => trackBrandLogoClick()}
      href={urlify(slug)}
      underline="none"
    >
      <Stack
        alignItems="center"
        direction="row"
        bgcolor={theme.palette['color/background/elevated']}
        padding={1.5}
        borderRadius={2}
        sx={{
          '&:hover': {
            boxShadow: '0px 0px 10px 0px #3031330D',
          },
        }}
      >
        <Box position="relative" width={84} height={56}>
          {logo ? (
            <ContentfulImage
              src={logo}
              data-pw="brand-card-logo"
              alt={logo.fields.description ?? pageTitle}
              sizes={`(min-width:${theme.breakpoints.values.md}px) 50vw, 100vw`}
              fill
              style={{ objectFit: 'contain' }}
            />
          ) : null}
        </Box>

        <Typography data-pw="brand-card-title" typography="headingSmall" className="ml-2">
          {pageTitle}
        </Typography>
      </Stack>
    </Link>
  );
};
